<template>
    <modal class="departmentModel" :isShow="isShow">
        <div class="header" v-if="deparType==1">选择出品部门</div>
        <div class="header" v-else>选择菜品类别</div>
        <div class="modal-body">
            <div class="check" v-if="deparType==1">
                <el-tree
                    :data="CHHDPIDList"
                    show-checkbox
                    default-expand-all
                    node-key="id"
                    ref="tree"
                    highlight-current
                    :props="defaultProps">
                </el-tree>
            </div>
            <div class="check" v-else>
                <el-tree
                    :data="foodMainCategory"
                    show-checkbox
                    default-expand-all
                    node-key="id"
                    ref="tree"
                    highlight-current
                    :props="defaultProps">
                </el-tree>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="deparConfirm()">确认</button>
        </div>
    </modal>
</template>

<script>
//import { newGuid,printTrJson,printJson,printView } from '../../../../../../common'
import {httpAES,cacheData} from '../../../../../../services'

/**选择开卡人  */
export default {
    name:"departmentModel",
    emits:["closeModel","deparConfirm"],
    props:{ 
        isShow:Boolean,
        CHHDPIDList:Object,
        foodMainCategory:Object,
        deparType:Number
    },
    data(){
        return{
            /**开卡人 */
            posUsers:Object,
            posUsersCopy:Object,
            seleUser:Object,
            /**搜索 */
            defaultProps: {
                children: 'children',
                label: 'Name'
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
        },
        hide(){
            this.$emit("closeModel");
        },
        deparConfirm(){
            let deparList=this.$refs.tree.getCheckedNodes()
            this.$emit("deparConfirm",deparList);
        }
    }
}
</script>

<style lang="scss">
@import "./departmentModel.scss";
</style>